import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import React, { FunctionComponent, useContext } from "react";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import { translationMessages } from "./i18n";
import { ThemeProvider } from "@material-ui/core/styles";
import { light } from "./layout/theme.js";
import { StoresContext } from "./index";
import { observer } from "mobx-react";
import BasePage from "containers/base/BasePage";

const App: FunctionComponent<{}> = observer(() => {
  const {
    routingStore,
    uiStore: { locale }
  } = useContext(StoresContext);
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, routingStore);

  return (
    <IntlProvider locale={locale} messages={translationMessages[locale]}>
      <Router history={history}>
        <ThemeApp />
      </Router>
    </IntlProvider>
  );
});

const ThemeApp = () => {
  /*const {
    settingsStore: { darkMode }
  } = useContext(StoresContext);*/

  let theme = light;

  return (
    <ThemeProvider theme={theme}>
      <BasePage />
    </ThemeProvider>
  );
};

export default App;
