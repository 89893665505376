import { observable, action, decorate } from "mobx";
import { persist } from "mobx-persist";
import MapLayerModel from "./models/MapLayerModel";
import { LatLngType } from "../types/genericTypes";

export default class MapStore {
  /**
   * model used to control the layer of the maps
   */

  mapLayer: MapLayerModel = new MapLayerModel();

  latLng: LatLngType = {
    lat: 50.82803,
    lng: 3.26487
  };

  bounds: any = [
    [0, 0],
    [0, 0]
  ];

  zoomLevel: number = 14;

  setLatLng = (latLng: LatLngType) => {
    this.latLng = latLng;
  };

  setBounds = (bounds: any, pad = 0.3) => {
    if (bounds) bounds = bounds.pad(pad);
    this.bounds = bounds;
  };

  setZoomLevel = (zoomLevel: number) => {
    this.zoomLevel = zoomLevel;
  };

}

decorate(MapStore, {
  mapLayer: [observable, persist("object", MapLayerModel)],
  latLng: [observable, persist("object")],
  bounds: [observable],
  zoomLevel: [observable],
  setLatLng: action.bound,
  setBounds: action.bound,
  setZoomLevel: action.bound,
});
