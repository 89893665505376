import React, { createContext } from 'react'
import { render } from 'react-dom'
import { create } from 'mobx-persist'
import { RouterStore } from 'mobx-react-router'
import UiStore from './stores/UiStore'
import AuthStore from './stores/AuthStore'
import MapStore from './stores/MapStore'
import ApplicationStore from './stores/ApplicationStore'

import App from './App'

import * as serviceWorker from './serviceWorker'

// setup all stores
const routingStore = new RouterStore()
const uiStore = new UiStore()
const mapStore = new MapStore()
const authStore = new AuthStore(routingStore)
const applicationStore = new ApplicationStore()

const stores = {
  routingStore,
  uiStore,
  authStore,
  mapStore,
  applicationStore,
}

export const StoresContext = createContext(stores)

const persistStores = async () => {
  const hydrate = create()
  await hydrate('uiStore', stores.uiStore)
  await hydrate('authStore', stores.authStore)
  await hydrate('mapStore', stores.mapStore)
  // await hydrate('applicationStore', stores.applicationStore)
}

const renderApp = async () => {
  render(<div>loading application...</div>, document.getElementById('root'))
  await persistStores()
  render(
    <StoresContext.Provider value={stores}>
      <App />
    </StoresContext.Provider>,
    document.getElementById('root'),
  )
}

renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
