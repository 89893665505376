import { observable, decorate, action } from 'mobx'
import SearchAddressModel from './models/SearchAddressModel'
import { getCoordinatesForAddresses } from '../services/geo'
export default class ApplicationStore {
  /**
   * model used to control the layer of the maps
   */
  test: any[] = []
  addresses: SearchAddressModel[] = [
    /* new SearchAddressModel({
      street: 'Dosselstraat',
      country: 'Belgium',
      community: 'STADEN',
      postalCode: '8840',
      houseNumber: '',
    }), */
    /*  new SearchAddressModel({
      street: 'Korte molenstraat',
      country: 'Belgium',
      community: 'Sint-Andries',
      postalCode: '8200',
      houseNumber: '38',
    }), */
  ]

  addAddres = (data) => {
    this.addresses.push(new SearchAddressModel(data))
  }
  addAddresBatch = (data: any[]) => {
    this.addresses = [
      ...this.addresses,
      ...data.map((obj) => new SearchAddressModel(obj)),
    ]
  }
  deleteAddress = (id) => {
    this.addresses = this.addresses.filter((addresse) => addresse.id !== id)
  }
  convertAllAddresses = async () => {
    const noResultAddresses = this.addresses.filter(
      (address) => address.noValidAddress || true,
    )
    const formatedRequestForApi = noResultAddresses.map((address) => ({
      code: address.id,
      address: address.parsed,
    }))

    const convertedAddresses: any[] = await getCoordinatesForAddresses(
      formatedRequestForApi,
    )

    convertedAddresses.forEach((convertedAddress) => {
      let match = this.addresses.find(
        (address) => address.id === convertedAddress.code,
      )
      if (match) {
        match.updateCoordinates({
          lat: convertedAddress.position_wgs.lat,
          lng: convertedAddress.position_wgs.lng,
        })
        match.update('peliasUrl', convertedAddress.metaData.peliasUrl)
        match.update('searchedForAddress', true)
      }
    })
  }
}

decorate(ApplicationStore, {
  addresses: [observable],
  test: [observable],
  deleteAddress: action.bound,
  addAddresBatch: action.bound,
  addAddres: action.bound,
  convertAllAddresses: action.bound,
})
