import { observable, action, computed, decorate } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

/**
 * model used to control the layer of the maps
 * maplist is a json with al possible layers
 * when changing the activemapindex the map will be rerendered
 *
 */
class SearchAddressModel {
  constructor({
    street,
    country = 'België',
    community,
    postalCode,
    houseNumber,
  }) {
    this.id = uuidv4()
    this.street = street
    this.country = country
    this.community = community
    this.postalCode = postalCode
    this.houseNumber = houseNumber
  }
  id: string = ''
  street: string = ''
  country: string = ''
  community: string = ''
  postalCode: string = ''
  houseNumber: string = ''
  searchedForAddress: boolean = false
  peliasUrl = ''
  coordinates: { lat: number; lng: number } = { lat: 0, lng: 0 }

  update = (key, value) => {
    this[key] = value
  }

  updateCoordinates = ({ lat, lng }) => {
    this.coordinates = { lat, lng }
  }
  get parsed() {
    let obj: any = {}
    if (this.street !== '') obj.street = this.street
    if (this.country !== '') obj.country = this.country
    if (this.community !== '') obj.community = this.community
    if (this.postalCode !== '') obj.postalCode = this.postalCode
    if (this.houseNumber !== '') obj.houseNumber = this.houseNumber
    return obj
  }

  get noValidAddress() {
    return this.coordinates.lat === 0 && this.coordinates.lng === 0
  }
}

export default SearchAddressModel

decorate(SearchAddressModel, {
  id: [observable],
  street: [observable],
  country: [observable],
  community: [observable],
  postalCode: [observable],
  houseNumber: [observable],
  coordinates: [observable],
  peliasUrl: [observable],
  searchedForAddress: [observable],
  update: action.bound,
  updateCoordinates: action.bound,
  noValidAddress: computed,
})
