export const login = async (
  username: string,
  password: string
): Promise<boolean> => {
  return true;
};

export const logout = async (): Promise<boolean> => {
  return true;
};
