import React, { useContext, FunctionComponent } from 'react'
import {
  Drawer,
  Typography,
  Divider,
  ListItem,
  List,
  ListItemText,
  Button,
  IconButton,
  ListItemSecondaryAction,
  ListItemIcon,
} from '@material-ui/core'
import useSidebarStyles from './SidebarStyles'
import { observer } from 'mobx-react'
import { StoresContext } from 'index'
import { withStyles } from '@material-ui/styles'

import AddNewAddress from '../add-new-data/AddNewAddress'
import AddNewCsvAddress from '../add-new-data/AddNewCsvAddress'

import SyncIcon from '@material-ui/icons/Sync'
import DeleteIcon from '@material-ui/icons/Delete'

import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'

import OpenExternIcon from '@material-ui/icons/OpenInNew'

const Sidebar: FunctionComponent<{
  classes: any
}> = observer(({ classes }) => {
  const {
    applicationStore: { addresses, convertAllAddresses, deleteAddress },
  } = useContext(StoresContext)

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography variant="h6" className={classes.whiteText}>
          Orbit POC - Geocode {/* '==>API<==' */}
        </Typography>
      </div>
      <AddNewAddress />
      <Divider />
      <AddNewCsvAddress />
      <Divider />
      {addresses.length > 0 && (
        <Button
          sx={{ margin: 2 }}
          variant="outlined"
          startIcon={<SyncIcon />}
          onClick={(e) => convertAllAddresses()}
        >
          START CONVERTERING
        </Button>
      )}
      <List>
        {addresses.map((address) => (
          <ListItem key={address.id}>
            {address.searchedForAddress && (
              <ListItemIcon
                sx={{ color: address.noValidAddress ? '#f44336' : '#4caf50' }}
              >
                {address.noValidAddress ? <ErrorIcon /> : <DoneIcon />}
              </ListItemIcon>
            )}
            <ListItemText
              primary={`${address.street} ${address.houseNumber}`}
              secondary={`${address.postalCode} ${address.community}`}
            />
            <ListItemSecondaryAction>
              {address.peliasUrl && (
                <IconButton
                  sx={{ marginRight: '8px' }}
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    //@ts-ignore
                    window.open(address.peliasUrl, '_blank').focus()
                  }}
                >
                  <OpenExternIcon />
                </IconButton>
              )}
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => deleteAddress(address.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Divider />

      <div className={classes.grow} />
    </Drawer>
  )
})

export default withStyles(useSidebarStyles)(Sidebar)
