import React, { FunctionComponent, useContext } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import classNames from "classnames";
import { observer } from "mobx-react";

import useBasePageStyles from "./BasePageStyles";

import Sidebar from "../partials/sidebar/Sidebar";
import MapView from "views/Map/MapView";
import { withStyles } from "@material-ui/styles";
import { StoresContext } from "index";

interface Props {
  classes: any;
}

const BasePage: FunctionComponent<Props> = observer(({ classes }) => {
  const {
    uiStore,
    mapStore: {
      mapLayer: { maps }
    }
  } = useContext(StoresContext);

  return (
    <>
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar />
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: uiStore.sideBarOpen
        })}
      >
        {maps.length > 0 && <MapView />}
      </main>
    </div>
    </>
  );
});

export default withStyles(useBasePageStyles)(BasePage);
