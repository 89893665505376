import bbox from '@turf/bbox'
import { lineString } from '@turf/helpers'
import { StoresContext } from 'index'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import ReactMapGL, { Marker, Popup, ViewportProps } from 'react-map-gl'
import SearchAddressModel from 'stores/models/SearchAddressModel'
import WebMercatorViewport from 'viewport-mercator-project'

const MapView = observer(() => {
  const {
    mapStore: {
      latLng,
      zoomLevel,
      setZoomLevel,
      setLatLng,
      mapLayer: { activeMap },
    },
    applicationStore: { addresses },
  } = useContext(StoresContext)

  const [activeAddress, setActiveAddress] = useState<null | SearchAddressModel>(
    null,
  )
  const [heightWidth, setHeightWidth] = useState<{
    height: number
    width: number
  }>({ height: 0, width: 0 })

  const markerCount = addresses.filter((address) => !address.noValidAddress)
    .length

  const viewport: ViewportProps = {
    //@ts-ignore
    width: '100wh',
    //@ts-ignore
    height: '100vh',
    latitude: latLng.lat,
    longitude: latLng.lng,
    zoom: zoomLevel,
  }

  const testCoord:boolean = false

  useEffect(() => {
    if (markerCount === 0) return
    else if (markerCount === 1) {
      const validAddress = addresses.find((address) => !address.noValidAddress)
      setZoomLevel(18)
      setLatLng({
        //@ts-ignore
        lat: validAddress?.coordinates.lat,
        //@ts-ignore
        lng: validAddress?.coordinates.lng,
      })
    } else {
      const line = lineString(
        addresses
          .filter((address) => !address.noValidAddress)
          .map((address) => [address.coordinates.lng, address.coordinates.lat]),
      )
      const bboxCoord = bbox(line)
      const coordinatesArr: [[number, number], [number, number]] = [
        [bboxCoord[0], bboxCoord[1]],
        [bboxCoord[2], bboxCoord[3]],
      ]

      const viewport2 = new WebMercatorViewport({
        ...viewport,
        height: heightWidth.height,
        width: heightWidth.width,
      })

      const { longitude, latitude, zoom } = viewport2.fitBounds(
        coordinatesArr,
        {
          padding: 80,
        },
      )
      setZoomLevel(zoom)
      setLatLng({
        lat: latitude,
        lng: longitude,
      })
    }
  }, [addresses.filter((address) => !address.noValidAddress).length])

  return (
    <ReactMapGL
      {...viewport}
      maxZoom={activeMap.maxZoom}
      minZoom={activeMap.minZoom}
      mapboxApiAccessToken={activeMap.accessToken}
      mapStyle={activeMap.url}
      onViewportChange={(newViewport: ViewportProps) => {
        //  setViewport(newViewport); --> for saving in store
        const { latitude, longitude, zoom, height, width } = newViewport
        //@ts-ignore
        setZoomLevel(zoom)
        setLatLng({
          //@ts-ignore
          lat: latitude,
          //@ts-ignore
          lng: longitude,
        })
        if (height && width) {
          setHeightWidth({
            height,
            width,
          })
        }
      }}
    >
      {addresses
        .filter((address) => !address.noValidAddress)
        .map((address) => (
          <Marker
            key={address.id}
            latitude={address.coordinates.lat}
            longitude={address.coordinates.lng}
          >
            {testCoord && (<div style={{ height: 4, width: 4, backgroundColor: 'blue' }}></div>)}
            {!testCoord && (
              <div
                style={{ transform: `translate(${-40 / 2}px,${-40}px)` }}
                onClick={(e) => setActiveAddress(address)}
              >
                <img
                  src={
                    'https://homemadebuffetcompany.co.uk/wp-content/uploads/2015/01/map-marker-icon.png'
                  }
                  height={40}
                ></img>
              </div>
            )}
          </Marker>
        ))}
      {activeAddress && (
        <Popup
          longitude={activeAddress.coordinates.lng}
          latitude={activeAddress.coordinates.lat}
          closeButton
          onClose={(e) => setActiveAddress(null)}
        >
          <div>
            {activeAddress.street} {activeAddress.houseNumber},{' '}
            {activeAddress.postalCode} {activeAddress.community}
          </div>
        </Popup>
      )}
    </ReactMapGL>
  )
})

export default MapView
