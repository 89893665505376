import React, { useContext, FunctionComponent, useState } from 'react'

import { observer } from 'mobx-react'
import { StoresContext } from '../../../index'
import { Box, Grid, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const AddNewAddress: FunctionComponent<{}> = observer(() => {
  const {
    applicationStore: { addAddres },
  } = useContext(StoresContext)
  const [street, setStreet] = useState('')
  const [houseNumber, setNumber] = useState('')
  const [postalCode, setPostalcode] = useState('')
  const [community, setCommunity] = useState('')

  const onSave = (e) => {
    addAddres({ street, houseNumber, postalCode, community })
    // setStreet("")
    // setNumber("")
    // setPostalcode("")
    // setCommunity("")
  }
  return (
    <>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <TextField
              id="outlined-required"
              label="Straat"
              defaultValue=""
              onChange={(e) => setStreet(e.target.value)}
              value={street}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-required"
              label="Nummer"
              defaultValue={''}
              value={houseNumber}
              onChange={(e) => setNumber(e.target.value)}
            />{' '}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-required"
              label="Postcode"
              defaultValue=""
              value={postalCode}
              onChange={(e) => setPostalcode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-required"
              label="Gemeente"
              value={community}
              onChange={(e) => setCommunity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              id="outlined-required"
              label="Land"
              defaultValue="België"
            />{' '}
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              sx={{
                marginTop: 2,
              }}
              onClick={onSave}
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Adres toevoegen
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
})

export default AddNewAddress
