import { Box, Button, Dialog } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { StoresContext } from 'index'
import { observer } from 'mobx-react'
import React, {
  FunctionComponent,
  useContext,
  useState
} from 'react'
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'
import './styles.css'

const AddNewCsvAddress: FunctionComponent<{}> = observer(() => {
  const {
    applicationStore: { addAddresBatch },
  } = useContext(StoresContext)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onSave = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Dialog open={isOpen} fullWidth onClose={(e) => setIsOpen(false)}>
        <Importer
          chunkSize={10000} // optional, internal parsing chunk size in bytes
          assumeNoHeaders={true} // optional, keeps "data has headers" checkbox off by default
          restartable={false} // optional, lets user choose to upload another file when import is complete
          processChunk={async (rows, { startIndex }) => {
            addAddresBatch(rows)
          }}
          onComplete={onSave}
          onClose={({ file, preview, fields, columnFields }) => {
            console.log('close')
          }}
        >
          <ImporterField name="street" label="Straat" />
          <ImporterField name="houseNumber" label="Huisnummer" optional />
          <ImporterField name="postalCode" label="Postcode" />
          <ImporterField name="community" label="Gemeente" />
          <ImporterField name="country" label="Land" optional />
        </Importer>
      </Dialog>
      <Box sx={{ margin: 2 }}>
        <Button
          fullWidth
          sx={{
            marginTop: 2,
          }}
          onClick={() => setIsOpen(true)}
          variant="outlined"
          startIcon={<AttachFileIcon />}
        >
          CSV upload
        </Button>
      </Box>
    </>
  )
})

export default AddNewCsvAddress
