import { observable, action, decorate } from "mobx";
import { persist } from "mobx-persist";
import { APP_LOCALES } from "../constants";

export default class UiStore {
  locale: APP_LOCALES = APP_LOCALES.NL;

  sideBarOpen: boolean = true;
  sideBarRightOpen = false;

  setSideBarOpen = (isOpen: boolean) => {
    this.sideBarOpen = isOpen;
  };

  setSideBarRightOpen = (isOpen: boolean) => {
    this.sideBarRightOpen = isOpen;
  };
}

decorate(UiStore, {
  locale: [observable, persist],
  sideBarOpen: [observable],
  sideBarRightOpen: [observable],
  setSideBarOpen: action.bound,
  setSideBarRightOpen: action.bound
});
