import axios from "axios";

export const getCoordinatesForAddresses = async (addresses: any[]) => {
  const API_URL = "https://geocoder.platform.orbitgis.com"
  // const API_URL = "http://localhost:3000"
  const { data } = await axios.post(API_URL, {
    locations: addresses,
    resultFormat: "json",
  });
  return data
};
