import { SIDEBAR_CONFIG } from "../../constants";
import { Theme, createStyles } from "@material-ui/core/styles";

const useBasePageStyles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "row"
    },
    content: {
      flexGrow: 1,

      marginLeft: -SIDEBAR_CONFIG.width
    },
    contentShift: {
      marginLeft: 0
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    }
  });

export default useBasePageStyles;
