//import AccessControl from "accesscontrol";

import { observable, action, decorate, computed } from "mobx";
import { RouterStore } from "mobx-react-router";
import { persist } from "mobx-persist";

import { login, logout } from "../services/auth";

import { ROUTE_BASE } from "routes/RouteList";
import { firstLetterUpperCase } from "../utils";

/* type canEditType = (
  rolePermissions: { roleId: string; permissions: any[] },
  resource: string
) => boolean;
 */
type UserType = { firstName: string; lastName: string };

/* const canEdit: canEditType = (rolePermissions, resource) => {
  let granted: boolean = false;
  const { permissions, roleId } = rolePermissions;
  //@ts-ignore
  const access = new AccessControl(permissions);
  let action: string = "UPDATE";
  let crud: string[] = ["CREATE", "READ", "UPDATE", "DELETE"];
  if (crud.indexOf(action) > -1)
    granted = access.can(roleId)[action.toLowerCase() + "Any"](resource)
      .granted;
  return granted;
}; */

export default class AuthStore {
  constructor(routingStore: RouterStore) {
    this.routingStore = routingStore;
  }
  routingStore: RouterStore;

  isLoggedIn: boolean = false;

  user: UserType = { firstName: "", lastName: "" };

  availableProfiles = [];

  rolePermissions = {};

  token = null;

  canEdit = false;

  doLogin = async (
    email: string,
    password: string
  ): Promise<{ success: boolean; error: string }> => {
    this.isLoggedIn = await login(email, password);
    let error: string = "";
    let success: boolean = true;
    if (success) {
      this.routingStore.push(ROUTE_BASE);
      // this.user = user;
      // this.token = token;
      // this.availableProfiles = profiles;
      // this.rolePermissions = rolePermissions;
      // this.isLoggedIn = true;
      // if (this.rolePermissions) {
      //   this.canEdit = canEdit(rolePermissions, "???");
      //   this.routingStore.push(ROUTE_BASE);
      // } else {
      //   this.routingStore.push(ROUTE_SELECT_PROFILE);
      // }
    }
    return { success, error };
  };

  /**
   * returns logged in status
   */
  doLogout = async (): Promise<boolean> => {
    await logout();
    this.isLoggedIn = false;
    this.availableProfiles = [];
    this.rolePermissions = {};
    this.token = null;
    return this.isLoggedIn;
  };

  forceIsLoggedInFalse = () => {
    this.isLoggedIn = false;
    this.availableProfiles = [];
    this.rolePermissions = {};
    this.token = null;
  };

  get displayName() {
    let displayName = "";
    if (this.user && this.user.lastName) {
      displayName = `${this.user.lastName.toUpperCase()} ${firstLetterUpperCase(
        this.user.firstName
      )}`;
    }
    return displayName;
  }
}

decorate(AuthStore, {
  isLoggedIn: [observable, persist],
  user: [observable, persist("object")],
  availableProfiles: [observable, persist("list")],
  rolePermissions: [observable, persist("object")],
  token: [observable, persist],
  canEdit: [observable, persist],
  doLogin: action.bound,
  doLogout: action.bound,
  forceIsLoggedInFalse: action.bound,
  displayName: computed
});
